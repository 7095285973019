<template>
	<div class="page-web page_legal">
		<Head />
		<div class="legal-section">
			<div class="container">
				<div class="legal-content">
					<div class="legal-tip">{{$t('cr1Txt')}}</div>
					<div class="legal-flex">
						<div class="legal-flex__item"><span class="iconfont icon-success"></span>{{$t('cr1Txt2')}}</div>
						<div class="legal-flex__item"><span class="iconfont icon-success"></span>{{$t('cr1Txt3')}}</div>
						<div class="legal-flex__item"><span class="iconfont icon-success"></span>{{$t('cr1Txt4')}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="legal2-section">
			<div class="container">
				<div class="legal2-btns">
					<el-button type="primary" @click="pushAd">{{$t('cr1btn')}}</el-button>
					<el-button type="info">{{$t('cr1btn2')}}</el-button>
				</div>
				<div class="legal2-panel">
					<div class="legal2-hd">{{$t('cr1Txt5')}}</div>
					<div class="legal2-bd">
						<el-row :gutter="20">
							<el-col :xs="12" :sm="8">
								<div class="legal2-box">
									<div class="legal2-pic">
										<img src="../../assets/photo/web/qy1.jpg" alt="">
									</div>
									<div class="legal2-content">
										<div class="legal2-tt">{{$t('cr1Txt6')}}</div>
										<div class="legal2-desc line-2">{{$t('cr1Txt8')}}</div>
									</div>
								</div>
							</el-col>
							<el-col :xs="12" :sm="8">
								<div class="legal2-box">
									<div class="legal2-pic">
										<img src="../../assets/photo/web/qy2.jpg" alt="">
									</div>
									<div class="legal2-content">
										<div class="legal2-tt">{{$t('cr1Txt7')}}</div>
										<div class="legal2-desc line-2">{{$t('cr1Txt7')}}</div>
									</div>
								</div>
							</el-col>
							<el-col :xs="12" :sm="8">
								<div class="legal2-box">
									<div class="legal2-pic">
										<img src="../../assets/photo/web/qy3.jpg" alt="">
									</div>
									<div class="legal2-content">
										<div class="legal2-tt">{{$t('cr1Txt7')}}</div>
										<div class="legal2-desc line-2">{{$t('cr1Txt9')}}</div>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		securitySet
	} from '@/api/api/user'
	// @ is an alias to /src
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			Foot
		},
		data() {
			return {
				activeName: 'first',
				options: [{
					value: '选项1',
					label: '美国1'
				}, {
					value: '选项2',
					label: '美国2'
				}],
				value: '',
				tableData: [{
						business: "UTT_OTC_EXCHANGE",
						number: "40",
						mode: "现金当面交易",
						num: "9,553.43 USDT",
						quota: "$1,000.00-$75,090.01",
						price: "7.86",
					},
					{
						business: "UTT_OTC_EXCHANGE",
						number: "40",
						mode: "支付宝",
						num: "9,553.43 USDT",
						quota: "$1,000.00-$75,090.01",
						price: "7.86",
					},
					{
						business: "UTT_OTC_EXCHANGE",
						number: "40",
						mode: "现金当面交易",
						num: "9,553.43 USDT",
						quota: "$1,000.00-$75,090.01",
						price: "7.86",
					},
					{
						business: "UTT_OTC_EXCHANGE",
						number: "40",
						mode: "支付宝",
						num: "9,553.43 USDT",
						quota: "$1,000.00-$75,090.01",
						price: "7.86",
					}
				],
				options1: [{
					value: '选项1',
					label: '美国1'
				}, {
					value: '选项2',
					label: '美国2'
				}],
				value1: '',
				tableData1: [{
						business: "UTT_OTC_EXCHANGE1",
						number: "40",
						mode: "现金当面交易",
						num: "9,553.43 USDT",
						quota: "$1,000.00-$75,090.01",
						price: "7.86",
					},
					{
						business: "UTT_OTC_EXCHANGE",
						number: "40",
						mode: "支付宝",
						num: "9,553.43 USDT",
						quota: "$1,000.00-$75,090.01",
						price: "7.86",
					},
					{
						business: "UTT_OTC_EXCHANGE",
						number: "40",
						mode: "现金当面交易",
						num: "9,553.43 USDT",
						quota: "$1,000.00-$75,090.01",
						price: "7.86",
					},
					{
						business: "UTT_OTC_EXCHANGE",
						number: "40",
						mode: "支付宝",
						num: "9,553.43 USDT",
						quota: "$1,000.00-$75,090.01",
						price: "7.86",
					}
				],
				LgealList: [{
						pic: require("../../assets/photo/regi.png"),
						name: "动态调整",
						info: "根据市场价格实时波动",
					},
					{
						pic: require("../../assets/photo/regi.png"),
						name: "免手续费",
						info: "用户所见即所得，买卖价格外无需任何平台手续费",
					},
					{
						pic: require("../../assets/photo/regi.png"),
						name: "即时成交",
						info: "引入平台服务商家，智能匹配，成交订单，无须等待撮合",
					},
					{
						pic: require("../../assets/photo/regi.png"),
						name: "平台担保",
						info: "平台认证商家，安全有保障，24小时客服为交易保驾护航",
					}
				]
			};
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				securitySet().then(res => {
					if (res.code == 0) {
						if (res.data.realName == null || res.data.realName == "") {
							this.$message.warning(this.$t('submittip1'));
							this.$router.push('/admin');
						} else if (res.data.phoneVerified == 0) {
							this.$message.warning(this.$t('submittip2'));
							this.$router.push('/admin');
						} else if (res.data.fundsVerified == 0) {
							this.$message.warning(this.$t('submittip3'));
							this.$router.push('/admin');
						}
					} else {
						this.$message.error(res.message)
					}
				})
			},
			pushAd(){
				this.$router.push('/createAd');
			}
		}
	}
</script>
